.Home {
    display: flex;
}

.HomeContent {
    padding: 15px 30px;
    background-color: #F7EFEF; 
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.HomeContent a.nav-link {
    background-color: #9c9595;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    color: #F7EFEF;   
}



.HomeContent a.nav-link:hover {
    color: #000;
}


.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    flex-wrap: wrap;
    margin-left: 40px;
    border-radius: 8px;
}

 .grid{
    display: grid !important; 
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
    width: 100%;
}

.atalho {
    margin-right: 30px;
    margin-bottom: 15px;
    max-width: 150px;
    width: 150px;
    text-align: center;
    min-width: 150px;
    border-radius: 8px;
}


.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #000 !important;
}

.MuiTableSortLabel-root:hover {
    color: #000 !important;
}

.MuiTableSortLabel-icon {
    color: #000 !important;
}

.MuiTableHead-root .MuiCheckbox-root {
    color: white !important;
}

.atalhoControle {
    margin-bottom: 5px;
    background-color: #9c9595;
    color: white;
}