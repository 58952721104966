@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

*, html {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Ubuntu', sans-serif !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Ubuntu', sans-serif;

}


html {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  font-size: 0.86rem;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.defaultHeader {
  display: flex;
}

.defaultHeader h3 {
  flex: 1;
  /* margin-right: 10px; */
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: #F7EFEF;
  /* color: black; */
  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
  border-radius: 6px 0px 0px 6px;
}

.defaultHeader h3 strong {
  text-indent: 3px;
  border-radius: 6px;
}

button:focus {outline:0;}

::-webkit-scrollbar {
  width: 17px;
  height: 17px;
 }

::-webkit-scrollbar-thumb {
  background: #f7efef;
  border-radius: 0px;
  border: 3px solid #9c9595;
}

::-webkit-scrollbar-thumb:hover{
  background: #f7efef;
}

::-webkit-scrollbar-track{
  background: #9c9595;
  border-radius: 0px;
  border: 3px solid #9c9595;
}

button {
  border-radius: 4px;
}

.regForm {
  border-radius: 4px;
}

iframe {
  pointer-events: none;
}

iframe.embed-responsive-item{
  pointer-events: all;
}

