.Manut {
    display: flex;
    background-color: #F7EFEF;
}

.ManutContent {
    padding: 15px 30px;
    background-color: #F7EFEF;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.ManutContent-frame {
    padding: 20px;
    padding-bottom: 15px;
    background-color: #F7EFEF;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.Calendar {
    padding: 20px;
    padding-bottom: 50px;
    background-color: #9c9595;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
   
}

.tableDivRelacionados {
    margin-top: 20px;
    border: 1px solid #9c9595;
    padding: 0px;
    border-radius: 5px;
}

.tableDivRelacionados h2 {
    background-color: #9c9595;
    color: white;
    font-size: 1.2rem;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 0px;
}