.defaultReg {
    display: flex;
    background-color: #F7EFEF;
}

.defaultRegContent {
    padding: 15px 30px;
    background-color: #F7EFEF;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    border-radius: 4px;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
    
    height: 30px;
    transition: all .1s  ease-in-out;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: #000;
    outline: 1px solid #000;
    outline-offset: -2px;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid;
    /* border-bottom: 2px solid #9c9595; */
    border-bottom: 2px solid #9c9595;

}

.selectFileButton {
    border: none;
    width: 200px;
    background-color: #9c9595;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}


.incluiFileButton {
    border: none;
    width: 100px;
    background-color: #9c9595;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

#html_btn {
	display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regButtonsTab {
    display: inline-flex;
    overflow: auto;
    /* flex-flow: row; */
    /* margin-top: 10px; */
    align-items: flex-start; 
    align-content: flex-start; 
    justify-content: flex-start;
    margin-left: 50px;
    
}
.teste {
    display: inline-block;
    overflow: auto;
    /* columns: 100px 2; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */ 
    /* justify-content: flex-end; */
    /* margin-left: 430px; */
    
}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
    
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
    
}

.dadosGeraisCliente {
    margin-bottom: 30px;
    border: 2px solid #9c9595;
    padding: 24px;

}

#regForm h4 {
    flex: 1;
    background-color: #9c9595;
    color: white;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
    font-weight: 500;
    border-top: 2px solid #9c9595;
}

.grupoEdits {
    border: 2px solid #9c9595;
    padding: 20px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    border-radius: 4px;
}

.grupoTitulo {
    flex: 1;
    background-color: #9c9595;
    color: white;
    white-space: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 0px;
    font-weight: 500;
    padding-top: 4px;
    display: flex;
    vertical-align: middle;
}

.regRelacionados {
    border: 2px solid #9c9595;
}

.atalhoRelacionados {
    background-color: #9c9595;
    color: white;
    vertical-align: center;
}

.atalhoRelacionados svg {
    margin-right: 5px;
}

.manutReg.Relacionados {
    padding: 10px;
}



.grupoEdits button {
    padding: 5px;
    border: none;
    background-color: #9c9595;
    color: white;
    margin-left: 10px;
    margin-bottom: 10px;    
    transition: all .1s  ease-in-out;
  }

 