.filtersDiv {
    border-bottom: 2px solid #ff6a00;
    /* border-bottom: 2px solid #10035c; */
    padding-bottom: 15px;
    padding-top: 15px;
}

.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.tableDiv-frame {
    padding-bottom: 30px;
    border-bottom: 2px solid #ff6a00;
}

.register {
    border-radius: 4px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
    border-radius: 4px;
            
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: #000;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    @page {size: A4 landscape; }

    * {
        color: black !important; 
        font-size: 0.6rem !important;
    }

    .MuiTableContainer-root {
        overflow-x: hidden !important;
    }
}

.modalAcompanhamento {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color:  white;
    padding: 30px;
    max-width: 80vw;
    max-height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-content: center;
    align-items: center;
}

.modalAcompanhamento form {
    width: 100%;
}

.modalAcompanhamento button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
            
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.modalAcompanhamento button:hover:not(.MuiButtonBase-root) {
    color: #000;
}

.modalAcompanhamento button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.modalAcompanhamento button {
    margin-top: 10px;
}

.modalAcompanhamento form button {
    margin-top: 0;
}

.swal2-container {
    z-index: 9990 !important;
}