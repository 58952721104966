.userOptions {
    right: 40px;
    /* background-color: #9c9595; */
    background-color: #9c9595;

    /* border: 3px solid #F7EFEF; */
    border-top: none;
    /* border-radius: 0px 0px 3px 3px; */
    padding-left: 7px;
    padding-top: 8px;
    color: #F7EFEF;
    /* color: black; */
    height: 40px;
    z-index: 33;
    width: 250px;
    border-radius: 0px 6px 6px 0; 
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.userName {
    display: inline;
}

.userOptions button {
    margin-left: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: #F7EFEF;
    /* color: black;     */
    float: right;
    border: 1px solid transparent;
    
}

.userOptions button:hover {
    border-radius: 2px;
    border: 1px solid #000;
    /* border: 1px solid black; */

    color: #000;
    /* color: black; */

    /* margin-left: 19px;
    margin-right: 4px;
    margin-top: -1px; */
}