.Login {
    flex: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    flex-direction: column;
    color: #9c9595;
}

.Login::after {
    content: "";

    background: #9c9595;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #040125, #9c9595, #040125);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #040125, #9c9595, #040125); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    /* opacity: 0.8; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}


.Login .logo {
    margin-top: 15px;
    margin-bottom: 15px;
    align-self: stretch;
    max-width: 300px;
}

.Login p {
    margin-top: -15px;
    margin-bottom: 40px;
}


.Login .form {
   flex: 1;
   display: flex;
   align-content: center;
   justify-content: center;
   flex-direction: column;
}

.Login .form-signin {
    background-color:#F7EFEF;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #9c9595;
    border-radius: 5px;
    padding: 15px;
    max-height: 420px;
}


.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #9c9595;
}

:root {
    --input-padding-x: 1.2rem;
    --input-padding-y: 0.9rem;
}

.form a {
    color: #9c9595;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 0;
    color: #9c9595;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    margin-top: 5px;
}

.form-label-group>input {
    color: #9c9595;
}

.form-label-group>input,
.form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
    position: relative;
    margin-bottom: 10px;
}

.loginButton {
    background-color: #9c9595;
}

.loginButtonText {
    display: inline;
    color: #F7EFEF;
}
  
.loginButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.loginButton span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.loginButton:hover span {
    padding-right: 25px;
}

.loginButton:hover span:after {
    opacity: 1;
    right: 0;
}

.loginButton:hover .loginButtonText {
    display: inline;
    color: #9c9595;
}

.loginText {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.1em;
}
  
.loginInput {
    font-size: 1.1em;
    height: 15px;
    border: 3px solid #9c9595;
    border-radius: 3px;
    width: 100%;
}


.loginButton {
    background-color: #9c9595;
	margin-top: 15px;
	border-radius: 3px;
	vertical-align: center;
	text-align: center;
	width: 100%;
    height: 30px;
    min-height: 40px;
    border: 3px solid #9c9595;
    padding: 3px;
    color: #9c9595;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 15px;
}


.Login .delphus {
    margin-bottom: 50px;
    margin-top: 5vh;
}

.Login .delphus .logo_delphus {
    max-width: 200px;
}

.forgotPwDiv {
    align-self: center;
    text-align: center;
}


.loginButton:hover{
    background-color: #F7EFEF;
}
