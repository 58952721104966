.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  transform: translateY(4px);
}

.manutReg {
  flex: 1;
  display: flex;
}

.manutReg .register {
  flex: 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}


.relacionados {
  display: flex;
  flex: 1;
  width: 100%;
  border: 2px solid #9c9595;
  min-height: 50vh;
  padding-bottom: 1px;
}

.dadosGeraisCliente {
  border-radius: 0px 0px 4px 4px;
}

.buttonReplica {
  border: 1px solid white;
  background-color: #9c9595;
  color: white;
  margin-right: 20px;
  transition: all .1s  ease-in-out;
  padding-right: 5px;
  padding-left: 5px;
}
.grupoEdits{
  border-radius: 0px 0px 4px 4px;
}

.grupoTitulo{
  border-radius: 4px 4px 0px 0px;
}



.buttonReplica:hover {
  color: #000;
  border: 1px solid #000;
}

.buttonReplica:active {
  transform: translateY(2px);
}