.listRegister::-webkit-scrollbar {
    width: 10px;
    height: 10px;
   }
  
.listRegister::-webkit-scrollbar-thumb {
    background: #9c9595;
    border-radius: 0px;
    border: 1px solid #f7efef;
  }
  
.listRegister::-webkit-scrollbar-thumb:hover{
    background: rgba(245, 160, 100, 0.8);
  }
  
.listRegister::-webkit-scrollbar-track{
    background: #f7efef;
    border-radius: 0px;
    border: 1px solid #f7efef;
}

.listRegister {
    border: 3px solid #9c9595;
}

.listAddButton {
  background: #f7efef;
  flex: 1;
  border: none;
  margin-bottom: 10px;
  color: #9c9595;
  border: 1px solid #9c9595;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  transition: all .1s ease-in-out;
  border-radius: 4px;
}

.listAddButton i svg{
  transition: all .1s  ease-in-out;
}

.listAddButton:hover > i > svg{
  color: #000 !important;
}

.listAddButton:hover {
  background: #9c9595;
  color: #000 !important;
  outline: 1px solid #000;
  outline-offset: -3px;
}

.detailedFiltersDiv {
  color: #9c9595;
  border: 1px solid #9c9595;
  padding: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.detailedFiltersDiv p{
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.clearFilterButton {
  background: #9c9595;
  flex: 1;
  border: none;
  margin-bottom: 10px;
  color: white;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  transition: all .1s ease-in-out;
}

.clearFilterButton i svg{
  transition: all .1s  ease-in-out;
  margin-top: -3px;
}

.clearFilterButton:hover > i > svg{
  color: #000 !important;
}

.clearFilterButton:hover {
  background: #9c9595;
  color: #000 !important;
  outline: 1px solid #000;
  outline-offset: -3px;
}