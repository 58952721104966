.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  transform: translateY(4px);
}

.manutReg {
  flex: 1;
  display: flex;
  border-radius: 4px;
}

.manutReg .register {
  flex: 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ScrollWrapper {
  overflow-x: auto;
  width: 100%;
  border: 3px solid #9c9595;
  -webkit-overflow-scrolling: touch;
  padding: 30px;
}

.RegLotesImagesUpload {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  vertical-align: middle;
}

.multipleImagesButton{
  border: none;
  width: 200px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin-bottom: 10px;
}

.multipleImagesButton:hover {
  color: #000;
}

.multipleImagesButton:active {
  transform: translateY(4px);
}

#html_btn_fotos {
	display: none;
}
