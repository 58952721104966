@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Ubuntu', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Ubuntu', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Login {
    flex: 1 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    flex-direction: column;
    color: #9c9595;
}

.Login::after {
    content: "";

    background: #9c9595;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #040125, #9c9595, #040125); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    /* opacity: 0.8; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}


.Login .logo {
    margin-top: 15px;
    margin-bottom: 15px;
    align-self: stretch;
    max-width: 300px;
}

.Login p {
    margin-top: -15px;
    margin-bottom: 40px;
}


.Login .form {
   flex: 1 1;
   display: flex;
   align-content: center;
   justify-content: center;
   flex-direction: column;
}

.Login .form-signin {
    background-color:#F7EFEF;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #9c9595;
    border-radius: 5px;
    padding: 15px;
    max-height: 420px;
}


.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(0.9rem + 0.9rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(0.9rem);
    padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(0.9rem / 2);
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(0.9rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #9c9595;
}

:root {
    --input-padding-x: 1.2rem;
    --input-padding-y: 0.9rem;
}

.form a {
    color: #9c9595;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 0;
    color: #9c9595;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    margin-top: 5px;
}

.form-label-group>input {
    color: #9c9595;
}

.form-label-group>input,
.form-label-group>label {
    padding: 0.9rem 1.2rem;
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
    position: relative;
    margin-bottom: 10px;
}

.loginButton {
    background-color: #9c9595;
}

.loginButtonText {
    display: inline;
    color: #F7EFEF;
}
  
.loginButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.loginButton span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.loginButton:hover span {
    padding-right: 25px;
}

.loginButton:hover span:after {
    opacity: 1;
    right: 0;
}

.loginButton:hover .loginButtonText {
    display: inline;
    color: #9c9595;
}

.loginText {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.1em;
}
  
.loginInput {
    font-size: 1.1em;
    height: 15px;
    border: 3px solid #9c9595;
    border-radius: 3px;
    width: 100%;
}


.loginButton {
    background-color: #9c9595;
	margin-top: 15px;
	border-radius: 3px;
	vertical-align: center;
	text-align: center;
	width: 100%;
    height: 30px;
    min-height: 40px;
    border: 3px solid #9c9595;
    padding: 3px;
    color: #9c9595;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 15px;
}


.Login .delphus {
    margin-bottom: 50px;
    margin-top: 5vh;
}

.Login .delphus .logo_delphus {
    max-width: 200px;
}

.forgotPwDiv {
    align-self: center;
    text-align: center;
}


.loginButton:hover{
    background-color: #F7EFEF;
}

.Navbar {
    display: flex;
    width: 232px;
    min-height: 100vh;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #FF6A00;
    padding-left: 1px;
    padding-bottom: 20px;
}

.Navbar-a {
    display: flex;
    width: 232px;
    min-height: 100vh;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #FF6A00;
    padding-left: 1px;
    padding-bottom: 20px;
    -webkit-animation: grow 0.4s;
            animation: grow 0.4s;
}

.Navbar-hide {
    display: flex;
    width: 80px;
    min-height: 100vh;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #FF6A00;
    padding-left: 3px;    
}

.Navbar-a-hide {
    display: flex;
    width: 80px;
    min-height: 100vh;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #FF6A00;
    padding-left: 3px;    
    -webkit-animation: shrink 0.4s;    
            animation: shrink 0.4s;
}

.fr_icon_navbar {
    width: 40px;
}

.fr_logo_navbar {
    display: flex;
    flex: 1 1;
    width: 200px;
    vertical-align: center;
}

.fr_icon_navbar-a {
    width: 40px;
    -webkit-animation: shrink-logo 0.4s;
            animation: shrink-logo 0.4s;
}

.fr_logo_navbar-a {
    display: flex;
    flex: 1 1;
    width: 200px;
    vertical-align: center;
    -webkit-animation: grow-logo 0.4s;
            animation: grow-logo 0.4s;
}

a.atalho.nav-link {
    flex: 1 1;
    /* color: white; */
    color: #000;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

a.nav-link:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #000;


    
}

a.nav-link.active:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #000;

}

.toggle_navbar {
    background-color: transparent;
    border: none;
    color: white;
    /* color: black; */

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; /* Safari */
    transition-duration: 0.4s;
}


.toggleMenuButton:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #FF6A00;


}


.hide-toggleMenuButton:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #FF6A00;


}


.toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;
    
    /* color: #f1bf3b; */
    /* color: black; */
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    justify-content: left; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.toggleMenuButtonCollapse{
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    justify-content: left; /* Safari */
    transition-duration: 0.4s;
    padding-top: 10px;
    padding-bottom: 10px;
}

.toggleMenuButtonCollapse:hover {
    color: black;
}

.hide-toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;

    /* color: black; */

    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 0px;
    justify-content: center; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Open {
    /* background-color: rgb(35, 43, 27); */
    background-color: #FF6A00;
    border-radius: 2px 2px 0 0;

}

.toggleMenuButtonCollapse.Open {
    margin-top: 10px;
    margin-left: 5%;
    width: 90%;
}

.Open:hover {
    /* color: white; */
    /* color: #9c9595; */
    /* color: #FF6A00; */
    color: black;

}


.toggleMenu {
    /* border: solid 2px rgb(35, 43, 27);  */
    border: solid 2px #FF6A00;
    border-top: none;
    border-radius: 0px 0px 2px 2px;
}


.toggleMenuCollapse {
    border: solid 2px #FF6A00;
    border-top: none;
    margin-left: 5%;
    width: 90%;
}



.toggleMenuItem {
    flex: 1 1;
    color: white;
    /* color: black; */

    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px; /* Safari */
    transition-duration: 0.4s;
}

.toggleMenuItem:hover {
    color: black;
}

.hide-toggleMenuItem {
    flex: 1 1;
    color: white;
    /* color: black; */
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center; /* Safari */
    transition-duration: 0.4s;
}



/* -------------- ANIMAÇÕES --------------- */

@-webkit-keyframes grow {
    0% {
        width: 80px;
    }
    100% {
        width: 232px;
    }
}

@keyframes grow {
    0% {
        width: 80px;
    }
    100% {
        width: 232px;
    }
}
@-webkit-keyframes grow-logo {
    from {
        width: 40px;
    }
    to {
        width: 200px;
    }
}
@keyframes grow-logo {
    from {
        width: 40px;
    }
    to {
        width: 200px;
    }
}

@-webkit-keyframes shrink {
    from {
        width: 232px;
    }
    to {
        width: 80px;
    }
}

@keyframes shrink {
    from {
        width: 232px;
    }
    to {
        width: 80px;
    }
}

@-webkit-keyframes shrink-logo {
    from {
        width: 200px;
    }
    to {
        width: 40px;
    }
}

@keyframes shrink-logo {
    from {
        width: 200px;
    }
    to {
        width: 40px;
    }
}
.userOptions {
    right: 40px;
    /* background-color: #9c9595; */
    background-color: #9c9595;

    /* border: 3px solid #F7EFEF; */
    border-top: none;
    /* border-radius: 0px 0px 3px 3px; */
    padding-left: 7px;
    padding-top: 8px;
    color: #F7EFEF;
    /* color: black; */
    height: 40px;
    z-index: 33;
    width: 250px;
    border-radius: 0px 6px 6px 0; 
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.userName {
    display: inline;
}

.userOptions button {
    margin-left: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: #F7EFEF;
    /* color: black;     */
    float: right;
    border: 1px solid transparent;
    
}

.userOptions button:hover {
    border-radius: 2px;
    border: 1px solid #000;
    /* border: 1px solid black; */

    color: #000;
    /* color: black; */

    /* margin-left: 19px;
    margin-right: 4px;
    margin-top: -1px; */
}
.Home {
    display: flex;
}

.HomeContent {
    padding: 15px 30px;
    background-color: #F7EFEF; 
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.HomeContent a.nav-link {
    background-color: #9c9595;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    color: #F7EFEF;   
}



.HomeContent a.nav-link:hover {
    color: #000;
}


.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: flex-start;
    flex: 1 1;
    flex-wrap: wrap;
    margin-left: 40px;
    border-radius: 8px;
}

 .grid{
    display: grid !important; 
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
    width: 100%;
}

.atalho {
    margin-right: 30px;
    margin-bottom: 15px;
    max-width: 150px;
    width: 150px;
    text-align: center;
    min-width: 150px;
    border-radius: 8px;
}


.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #000 !important;
}

.MuiTableSortLabel-root:hover {
    color: #000 !important;
}

.MuiTableSortLabel-icon {
    color: #000 !important;
}

.MuiTableHead-root .MuiCheckbox-root {
    color: white !important;
}

.atalhoControle {
    margin-bottom: 5px;
    background-color: #9c9595;
    color: white;
}
.footerDelphus {
    vertical-align: bottom;
    align-self: flex-end;
    flex: 1 1;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 0.7rem;
    margin-top: 20px;
  }
.FrLoading {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.tableEdit, .tableDelete, .tableOk {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}

.tableOkRed {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: red;
}

.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #9c9595;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 80vw;
    border: 3px solid #9c9595;
    -webkit-overflow-scrolling: touch;
}

.DetailOfetasList {
    display: inline-flex;
    height: 270px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}

.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid #ff6a00;

    /* color: #9c9595; */
    color: #ff6a00;

}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.filtersDiv {
    border-bottom: 2px solid #ff6a00;
    /* border-bottom: 2px solid #10035c; */
    padding-bottom: 15px;
    padding-top: 15px;
}

.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.tableDiv-frame {
    padding-bottom: 30px;
    border-bottom: 2px solid #ff6a00;
}

.register {
    border-radius: 4px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
    border-radius: 4px;
            
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: #000;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    @page {size: A4 landscape; }

    * {
        color: black !important; 
        font-size: 0.6rem !important;
    }

    .MuiTableContainer-root {
        overflow-x: hidden !important;
    }
}

.modalAcompanhamento {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color:  white;
    padding: 30px;
    max-width: 80vw;
    max-height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    align-content: center;
    align-items: center;
}

.modalAcompanhamento form {
    width: 100%;
}

.modalAcompanhamento button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
            
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.modalAcompanhamento button:hover:not(.MuiButtonBase-root) {
    color: #000;
}

.modalAcompanhamento button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.modalAcompanhamento button {
    margin-top: 10px;
}

.modalAcompanhamento form button {
    margin-top: 0;
}

.swal2-container {
    z-index: 9990 !important;
}
.Manut {
    display: flex;
    background-color: #F7EFEF;
}

.ManutContent {
    padding: 15px 30px;
    background-color: #F7EFEF;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.ManutContent-frame {
    padding: 20px;
    padding-bottom: 15px;
    background-color: #F7EFEF;
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.Calendar {
    padding: 20px;
    padding-bottom: 50px;
    background-color: #9c9595;
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
   
}

.tableDivRelacionados {
    margin-top: 20px;
    border: 1px solid #9c9595;
    padding: 0px;
    border-radius: 5px;
}

.tableDivRelacionados h2 {
    background-color: #9c9595;
    color: white;
    font-size: 1.2rem;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 0px;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.defaultReg {
    display: flex;
    background-color: #F7EFEF;
}

.defaultRegContent {
    padding: 15px 30px;
    background-color: #F7EFEF;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    border-radius: 4px;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
    
    height: 30px;
    transition: all .1s  ease-in-out;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: #000;
    outline: 1px solid #000;
    outline-offset: -2px;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid;
    /* border-bottom: 2px solid #9c9595; */
    border-bottom: 2px solid #9c9595;

}

.selectFileButton {
    border: none;
    width: 200px;
    background-color: #9c9595;
    color: white;
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}


.incluiFileButton {
    border: none;
    width: 100px;
    background-color: #9c9595;
    color: white;
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

#html_btn {
	display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regButtonsTab {
    display: inline-flex;
    overflow: auto;
    /* flex-flow: row; */
    /* margin-top: 10px; */
    align-items: flex-start; 
    align-content: flex-start; 
    justify-content: flex-start;
    margin-left: 50px;
    
}
.teste {
    display: inline-block;
    overflow: auto;
    /* columns: 100px 2; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */ 
    /* justify-content: flex-end; */
    /* margin-left: 430px; */
    
}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    /* background-color: #9c9595; */
    background-color: #9c9595;
    color: white;
    /* color: black; */
    
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
    
}

.dadosGeraisCliente {
    margin-bottom: 30px;
    border: 2px solid #9c9595;
    padding: 24px;

}

#regForm h4 {
    flex: 1 1;
    background-color: #9c9595;
    color: white;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
    font-weight: 500;
    border-top: 2px solid #9c9595;
}

.grupoEdits {
    border: 2px solid #9c9595;
    padding: 20px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    border-radius: 4px;
}

.grupoTitulo {
    flex: 1 1;
    background-color: #9c9595;
    color: white;
    white-space: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 0px;
    font-weight: 500;
    padding-top: 4px;
    display: flex;
    vertical-align: middle;
}

.regRelacionados {
    border: 2px solid #9c9595;
}

.atalhoRelacionados {
    background-color: #9c9595;
    color: white;
    vertical-align: center;
}

.atalhoRelacionados svg {
    margin-right: 5px;
}

.manutReg.Relacionados {
    padding: 10px;
}



.grupoEdits button {
    padding: 5px;
    border: none;
    background-color: #9c9595;
    color: white;
    margin-left: 10px;
    margin-bottom: 10px;    
    transition: all .1s  ease-in-out;
  }

 
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid #ff6a00;

    /* color: #9c9595; */
    color: #ff6a00;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.listRegister::-webkit-scrollbar {
    width: 10px;
    height: 10px;
   }
  
.listRegister::-webkit-scrollbar-thumb {
    background: #9c9595;
    border-radius: 0px;
    border: 1px solid #f7efef;
  }
  
.listRegister::-webkit-scrollbar-thumb:hover{
    background: rgba(245, 160, 100, 0.8);
  }
  
.listRegister::-webkit-scrollbar-track{
    background: #f7efef;
    border-radius: 0px;
    border: 1px solid #f7efef;
}

.listRegister {
    border: 3px solid #9c9595;
}

.listAddButton {
  background: #f7efef;
  flex: 1 1;
  border: none;
  margin-bottom: 10px;
  color: #9c9595;
  border: 1px solid #9c9595;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  transition: all .1s ease-in-out;
}

.listAddButton i svg{
  transition: all .1s  ease-in-out;
}

.listAddButton:hover > i > svg{
  color: #000 !important;
}

.listAddButton:hover {
  background: #9c9595;
  color: #000 !important;
  outline: 1px solid #000;
  outline-offset: -3px;
}
.listRegister::-webkit-scrollbar {
    width: 10px;
    height: 10px;
   }
  
.listRegister::-webkit-scrollbar-thumb {
    background: #9c9595;
    border-radius: 0px;
    border: 1px solid #f7efef;
  }
  
.listRegister::-webkit-scrollbar-thumb:hover{
    background: rgba(245, 160, 100, 0.8);
  }
  
.listRegister::-webkit-scrollbar-track{
    background: #f7efef;
    border-radius: 0px;
    border: 1px solid #f7efef;
}

.listRegister {
    border: 3px solid #9c9595;
}

.listAddButton {
  background: #f7efef;
  flex: 1 1;
  border: none;
  margin-bottom: 10px;
  color: #9c9595;
  border: 1px solid #9c9595;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  transition: all .1s ease-in-out;
  border-radius: 4px;
}

.listAddButton i svg{
  transition: all .1s  ease-in-out;
}

.listAddButton:hover > i > svg{
  color: #000 !important;
}

.listAddButton:hover {
  background: #9c9595;
  color: #000 !important;
  outline: 1px solid #000;
  outline-offset: -3px;
}

.detailedFiltersDiv {
  color: #9c9595;
  border: 1px solid #9c9595;
  padding: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.detailedFiltersDiv p{
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.clearFilterButton {
  background: #9c9595;
  flex: 1 1;
  border: none;
  margin-bottom: 10px;
  color: white;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  transition: all .1s ease-in-out;
}

.clearFilterButton i svg{
  transition: all .1s  ease-in-out;
  margin-top: -3px;
}

.clearFilterButton:hover > i > svg{
  color: #000 !important;
}

.clearFilterButton:hover {
  background: #9c9595;
  color: #000 !important;
  outline: 1px solid #000;
  outline-offset: -3px;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}


.relacionados {
  display: flex;
  flex: 1 1;
  width: 100%;
  border: 2px solid #9c9595;
  min-height: 50vh;
  padding-bottom: 1px;
}

.dadosGeraisCliente {
  border-radius: 0px 0px 4px 4px;
}

.buttonReplica {
  border: 1px solid white;
  background-color: #9c9595;
  color: white;
  margin-right: 20px;
  transition: all .1s  ease-in-out;
  padding-right: 5px;
  padding-left: 5px;
}
.grupoEdits{
  border-radius: 0px 0px 4px 4px;
}

.grupoTitulo{
  border-radius: 4px 4px 0px 0px;
}



.buttonReplica:hover {
  color: #000;
  border: 1px solid #000;
}

.buttonReplica:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.FrImageEdit {
    flex: 1 1;
    color: #9c9595;
    /* margin-left: 50px; */
    display: flex;
    flex-direction: column;
    /* display: flex; */
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-right: 10px;
}

.FrImageOptions input {
    display: none;
} 

.FrImageContainer {
    display: inline;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 200px;
}

.FrImageEdit img {
    display: inline;

    /* flex: 1; */
    height: 200px;
    /* width: 150px; */
    border: 1px solid #9c9595;
}

.FrImageOptions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.FrImageOptions button {
    border: none;
    width: calc(50% - 2px);
    background-color: #9c9595;
    color: white; 
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 4px;
    /* margin-left: 10px; */
}

.FrImageOptions button:hover {
    color: #000;
}

.FrImageOptions button:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
  border-radius: 4px;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ScrollWrapper {
  overflow-x: auto;
  width: 100%;
  border: 3px solid #9c9595;
  -webkit-overflow-scrolling: touch;
  padding: 30px;
}

.RegLotesImagesUpload {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  vertical-align: middle;
}

.multipleImagesButton{
  border: none;
  width: 200px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-bottom: 10px;
}

.multipleImagesButton:hover {
  color: #000;
}

.multipleImagesButton:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

#html_btn_fotos {
	display: none;
}

.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row {
  margin-left: 0px;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid #ff6a00;

    /* color: #9c9595; */
    color: #ff6a00;

}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid #ff6a00;

    /* color: #9c9595; */
    color: #ff6a00;

}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.PrivateTabIndicator-colorSecondary-21 {
    background-color: #FF6A00 !important;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonF2 {
  background-color: #9c9595;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: white;
  /* color: black; */
  
  height: 30px; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.manutReg {
  flex: 1 1;
  display: flex;
}

.manutReg .register {
  flex: 1 1;
  /* background-color: #9c9595; */
  border: 1px solid #9c9595;
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.manutReg .register .buttons {
  /* height: 25px; */
  align-self: flex-end;
  margin-bottom: 10px;
}

.manutReg .register .fields {
  flex: 1 1;
  padding-left: 20px;
}


.manutReg .register .buttons button {
  padding: 5px;
  border: none;
  background-color: #9c9595;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.manutReg .register .buttons button:hover {
  color: #000;
  outline: 1px solid #000;
  outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
  padding: 5px;
  border: none;
  background-color: #c28f5f;
  color: #F7EFEF;
  margin-left: 10px;
  transition: all .1s  ease-in-out;
}

.flexImageFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
*, html {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Ubuntu', sans-serif !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Ubuntu', sans-serif;

}


html {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  font-size: 0.86rem;
}

.App {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.defaultHeader {
  display: flex;
}

.defaultHeader h3 {
  flex: 1 1;
  /* margin-right: 10px; */
  /* background-color: #9c9595; */
  background-color: #9c9595;
  color: #F7EFEF;
  /* color: black; */
  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
  border-radius: 6px 0px 0px 6px;
}

.defaultHeader h3 strong {
  text-indent: 3px;
  border-radius: 6px;
}

button:focus {outline:0;}

::-webkit-scrollbar {
  width: 17px;
  height: 17px;
 }

::-webkit-scrollbar-thumb {
  background: #f7efef;
  border-radius: 0px;
  border: 3px solid #9c9595;
}

::-webkit-scrollbar-thumb:hover{
  background: #f7efef;
}

::-webkit-scrollbar-track{
  background: #9c9595;
  border-radius: 0px;
  border: 3px solid #9c9595;
}

button {
  border-radius: 4px;
}

.regForm {
  border-radius: 4px;
}

iframe {
  pointer-events: none;
}

iframe.embed-responsive-item{
  pointer-events: all;
}


